import throttle from 'lodash/throttle';
import React, { useMemo, useState } from 'react';

export const useDivScroll = () => {
  const [isScrollEnd, setIsScrollEnd] = useState<boolean>(false);
  const [divScrollY, setDivScrollY] = useState<number>(0);

  const handleOnScroll = useMemo(
    () =>
      throttle((e: React.UIEvent<HTMLElement>) => {
        const { target } = e;
        const { scrollTop, scrollHeight, clientHeight } = target as HTMLElement;
        setDivScrollY(scrollTop);

        if (scrollTop + clientHeight + 1 >= scrollHeight) {
          setIsScrollEnd(true);
        } else {
          setIsScrollEnd(false);
        }
      }, 150),
    [],
  );

  return { divScrollY, isScrollEnd, handleOnScroll };
};
