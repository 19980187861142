import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import BaseModal, { PortalTarget } from '@/components/modal/BaseModal';
import { ModalProps } from '@/hooks//useModal';
import { useDivScroll } from '@/hooks/useDivScroll';
import { GLOBAL_COLOR } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

interface Padding {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export interface BottomSheetModalProps<D> {
  height?: number;
  header?: React.ReactNode;
  content: React.ReactNode;
  bottom?: React.ReactNode;
  headerPadding?: Padding;
  contentPadding?: Padding;
  bottomPadding?: Padding;
  modalProps: ModalProps<D>;
  isAutoHeight?: boolean;
  heightMinusCalc?: number;
  maxHeight?: number;
  isHeaderBorder?: boolean;
  autoClose?: boolean;
  isSetScrollTop?: boolean;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

const BottomSheetModal = <D extends any>({
  height,
  header,
  content,
  bottom,
  modalProps,
  headerPadding,
  contentPadding,
  bottomPadding,
  isAutoHeight = false,
  heightMinusCalc,
  maxHeight,
  isHeaderBorder = false,
  autoClose = true,
  isSetScrollTop = false,
  containerRef,
}: BottomSheetModalProps<D>) => {
  const { handleOnScroll, isScrollEnd } = useDivScroll();
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = containerRef || ref;
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (content && contentRef && contentRef.current) {
      if (contentRef.current?.firstChild) {
        if ((contentRef.current?.firstChild as HTMLElement)?.clientHeight > contentRef.current?.clientHeight) {
          setIsScrollable(true);
        } else {
          setIsScrollable(false);
        }
      }
    }
  }, [content, contentRef]);

  useEffect(() => {
    if (modalProps.isShowing && isSetScrollTop) {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }
  }, [contentRef, isSetScrollTop, modalProps.isShowing]);

  return (
    <BaseModal
      modalProps={modalProps}
      portalTarget={PortalTarget.BOTTOM_SHEET_ROOT}
      contentsOutSideTouchClosed={autoClose}
      isAutoHeight={isAutoHeight}>
      <Container isShow={modalProps.isShowing} maxHeight={maxHeight} height={height} heightMinusCalc={heightMinusCalc}>
        {header && (
          <HeaderWrapper isHeaderBorder={isHeaderBorder} {...headerPadding}>
            {header}
          </HeaderWrapper>
        )}
        <ContentWrapper ref={contentRef} onScroll={handleOnScroll} {...contentPadding}>
          {content}
        </ContentWrapper>
        {bottom && (
          <BottomWrapper isScrollEnd={isScrollEnd} isScrollable={isScrollable} {...bottomPadding}>
            {bottom}
          </BottomWrapper>
        )}
      </Container>
    </BaseModal>
  );
};

export default React.memo(BottomSheetModal);

const DEFAULT_PADDING_SIZE = '0px';
const getPadding = ({
  top = DEFAULT_PADDING_SIZE,
  right = DEFAULT_PADDING_SIZE,
  bottom = DEFAULT_PADDING_SIZE,
  left = DEFAULT_PADDING_SIZE,
}: Padding) => {
  return `${top} ${right} ${bottom} ${left}`;
};

interface ModalContainerParams {
  isShow: boolean;
  height?: number;
  heightMinusCalc?: number;
  maxHeight?: number;
}

export const Container = styled.section(
  ({ isShow, maxHeight, height, heightMinusCalc }: ModalContainerParams) => css`
    position: absolute;
    padding-bottom: env(safe-area-inset-bottom);
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${GLOBAL_COLOR.GRAY_900};
    background-color: ${GLOBAL_COLOR.WHITE};
    border-radius: ${toRem(20)} ${toRem(20)} 0 0;
    z-index: ${Z_INDEX.BOTTOM_MODAL};
    height: ${`${
      height && heightMinusCalc ? `calc(${height}vh - ${toRem(heightMinusCalc)})` : `${height}vh` || 'auto'
    }`};
    max-height: ${maxHeight}vh;

    ${!isShow
      ? css`
          animation: 300ms ${slideOut} forwards;
        `
      : css`
          animation: 300ms ${slideIn};
        `}
  `,
);

interface HeaderWrapperProps extends Padding {
  isHeaderBorder: boolean;
}

export const HeaderWrapper = styled.div(
  ({ isHeaderBorder, ...padding }: HeaderWrapperProps) => css`
    display: flex;
    padding: ${getPadding(padding)};
    justify-content: flex-start;
    align-items: center;
    height: ${toRem(54)};
    ${isHeaderBorder
      ? css`
          border-bottom: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_50};
        `
      : null}
  `,
);

export const ContentWrapper = styled.div<Padding>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: nowrap;
  overflow-y: scroll;
  padding: ${(props) => getPadding(props)};
`;

interface BottomWrapperProps extends Padding {
  isScrollEnd?: boolean;
  isScrollable?: boolean;
}

export const BottomWrapper = styled.div(
  ({ isScrollEnd, isScrollable, ...padding }: BottomWrapperProps) => css`
    display: flex;
    flex-direction: row;
    padding: ${getPadding(padding)};

    ${!isScrollable || (isScrollable && isScrollEnd)
      ? css`
          box-shadow: 0 0 0 rgba(0, 0, 0, 0);
          transition: box-shadow 0.15s ease-in-out;
        `
      : css`
          box-shadow: 0 ${toRem(-2)} ${toRem(6)} rgba(0, 0, 0, 0.05);
          transition: box-shadow 0.15s ease-in-out;
        `}
  `,
);

export const slideIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;

export const slideOut = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0.5;
  }
`;
