import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import { FlatIconType } from '@/constants/icon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface FilterListItemProps {
  text: string;
  onClick?: () => void;
  isFilterSelected?: boolean;
  iconType?: FlatIconType;
  iconSize?: number;
  containerStyle?: CSSProperties;
}

const FilterListItem = ({
  text,
  onClick,
  isFilterSelected,
  iconType,
  iconSize,
  containerStyle,
}: FilterListItemProps) => {
  return (
    <Container
      color={isFilterSelected ? GLOBAL_COLOR.BLUE_500 : GLOBAL_COLOR.GRAY_400}
      onClick={onClick}
      style={containerStyle}>
      <span>{text}</span>
      {iconType && (
        <FlatIcon
          iconType={iconType}
          size={iconSize}
          color={isFilterSelected ? GLOBAL_COLOR.BLUE_500 : GLOBAL_COLOR.GRAY_500}
        />
      )}
    </Container>
  );
};

export default React.memo(FilterListItem);

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  font-weight: 400;
  color: ${GLOBAL_COLOR.GRAY_700};
`;
