import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import IconButton from './IconButton';
import { FlatIconProps } from '@/constants/icon';
import { toRem } from '@/utils/commonUtils';

interface BottomSheetDefaultHeaderProps {
  title: string;
  leftButton?: FlatIconProps;
  rightButton?: FlatIconProps;
  onClickRightButton?: () => void;
}

const BottomSheetDefaultHeader = ({
  title,
  leftButton,
  rightButton,
  onClickRightButton,
}: BottomSheetDefaultHeaderProps) => {
  return (
    <HeaderContainer>
      {leftButton && <LeftButton {...leftButton} />}
      <Title>{title}</Title>
      {rightButton && <RightButton {...rightButton} onIconButtonClick={onClickRightButton} />}
    </HeaderContainer>
  );
};

export default BottomSheetDefaultHeader;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: ${toRem(18)};
  line-height: ${toRem(22)};
  letter-spacing: -0.02em;
`;

const ButtonConfig = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const LeftButton = styled(IconButton)`
  ${ButtonConfig};
  left: ${toRem(16)};
`;

const RightButton = styled(IconButton)`
  ${ButtonConfig};
  right: ${toRem(16)};
`;
