import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import ImageIcon from '@/components/common/ImageIcon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

export interface CheckBoxProps {
  children: ReactNode;
  isChecked: boolean;
  onClick: (isChecked: boolean) => void;
  disabled?: boolean;
  size?: number;
  isRound?: boolean;
  className?: string;
}

const CheckBox = ({ className, children, isChecked, onClick, disabled, size, isRound }: CheckBoxProps) => {
  return (
    <Container isChecked={isChecked} className={className}>
      <IconWrapper onClick={() => onClick(isChecked)}>
        {isChecked ? (
          <ImageIcon iconType={isRound ? 'icCheckboxFillRound' : 'icCheckboxFill'} size={size} />
        ) : (
          <ImageIcon iconType={isRound ? 'icCheckboxRound' : 'icCheckbox'} size={size} />
        )}
      </IconWrapper>
      <label className={'checkBoxLabel'}>
        <input className={'checkbox'} type={'checkbox'} onClick={() => onClick(isChecked)} disabled={disabled} />
        {children}
      </label>
    </Container>
  );
};

export default React.memo(CheckBox);

interface ContainerProps {
  isChecked: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  column-gap: ${toRem(4)};

  .checkBoxLabel {
    display: flex;
    align-items: center;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${toRem(16)};
    gap: ${toRem(4)};
  }

  .checkbox {
    display: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;
