import styled from '@emotion/styled';
import { ReactNode, useMemo } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import ImageIcon from '@/components/common/ImageIcon';
import { FlatIconType, ImageIconType } from '@/constants/icon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';
import { toRemIfNeed } from '@/utils/layoutUtils';

interface EmptyComponentProps {
  iconType: ImageIconType;
  iconSize?: number;
  emptyText: string;
  leftButtonText?: string;
  onLeftButtonClick?: () => void;
  leftButtonIcon?: FlatIconType;
  rightButtonText?: string;
  onRightButtonClick?: () => void;
  rightButtonType?: 'primary' | 'secondary' | 'default';
  className?: string;
  marginTop?: number | string;
  fontWeight?: number;
  extraBottomLink?: ReactNode;
}

const EmptyComponent = ({
  iconType,
  iconSize = 80,
  emptyText,
  leftButtonText,
  onLeftButtonClick,
  leftButtonIcon,
  rightButtonText,
  rightButtonType,
  onRightButtonClick,
  className,
  marginTop = 60,
  fontWeight = 500,
  extraBottomLink = <></>,
}: EmptyComponentProps) => {
  const rightButtonStyle = useMemo(() => {
    switch (rightButtonType) {
      case 'primary':
        return { borderColor: GLOBAL_COLOR.BLUE_500, textColor: GLOBAL_COLOR.BLUE_500 };
      case 'secondary':
        return { borderColor: GLOBAL_COLOR.ORANGE_500, textColor: GLOBAL_COLOR.ORANGE_500 };
      default:
        return { borderColor: GLOBAL_COLOR.GRAY_200, textColor: undefined };
    }
  }, [rightButtonType]);

  return (
    <Container className={className} marginTop={marginTop}>
      <ImageIcon iconType={iconType} size={iconSize} />
      <EmptyText>{emptyText}</EmptyText>
      <ButtonContainer>
        {leftButtonText && (
          <LeftButton type={'button'} onClick={onLeftButtonClick} fontWeight={fontWeight} className={'empty_reset'}>
            {leftButtonIcon && <FlatIcon iconType={'icReset'} color={GLOBAL_COLOR.GRAY_900} />}
            <span>{leftButtonText}</span>
          </LeftButton>
        )}
        {rightButtonText && (
          <RightButton
            type={'button'}
            onClick={onRightButtonClick}
            borderColor={rightButtonStyle.borderColor}
            textColor={rightButtonStyle.textColor}
            fontWeight={fontWeight}>
            {rightButtonText}
          </RightButton>
        )}
      </ButtonContainer>
      {extraBottomLink}
    </Container>
  );
};

export default EmptyComponent;

const Container = styled.div<{ marginTop: number | string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: ${({ marginTop }) => toRemIfNeed(marginTop)};
`;

const EmptyText = styled.pre`
  font-size: ${toRem(16)};
  font-weight: 400;
  line-height: ${toRem(22)};
  color: ${GLOBAL_COLOR.GRAY_500};
  margin-top: ${toRem(16)};
  margin-bottom: ${toRem(24)};
  text-align: center;
  font-family: inherit;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CommonButton = styled.button`
  border: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_200};
  border-radius: ${toRem(8)};
  padding: ${toRem(13)} ${toRem(16)};
  font-size: ${toRem(16)};
  line-height: ${toRem(20)};
  text-align: center;

  i {
    margin-right: ${toRem(4)};
  }
`;

const LeftButton = styled(CommonButton)<{ fontWeight: number }>`
  display: flex;
  align-items: center;
  margin-right: ${toRem(8)};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const RightButton = styled(CommonButton)<{ borderColor: string; textColor: string | undefined; fontWeight: number }>`
  border: ${toRem(1)} solid ${({ borderColor }) => borderColor};
  color: ${({ textColor }) => textColor};
  font-weight: ${({ fontWeight }) => fontWeight};
`;
