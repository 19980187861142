import styled from '@emotion/styled';
import React from 'react';
import SelectItem from './SelectItem';
import { Sort } from '@/constants/filter';
import { toRem } from '@/utils/commonUtils';

export interface SelectItemType {
  label: string;
  value: string | number | Sort;
}

interface RadioSelectListProps<T> {
  itemList: T[];
  selectedValue: T;
  handleSelect: (i: T) => void;
}

const RadioSelectList = <T extends SelectItemType>({
  itemList,
  selectedValue,
  handleSelect,
}: RadioSelectListProps<T>) => {
  return (
    <Container>
      {itemList.map((item: T, index: number) => {
        return (
          <SelectItem
            key={`select_${index}`}
            label={item.label}
            isSelected={selectedValue.value === item.value}
            onClick={() => handleSelect(item)}
          />
        );
      })}
    </Container>
  );
};

export default RadioSelectList;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)} 0;
`;
