import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import { FlatIconProps } from '@/constants/icon';
import { GLOBAL_COLOR } from '@/styles/colors';

interface StyledIconButtonProps {
  gap?: number;
}

export interface IconButtonProps extends FlatIconProps, StyledIconButtonProps {
  className?: string;
  children?: ReactNode;
  onIconButtonClick?: () => void;
}

const IconButton = ({
  iconType,
  size = 16,
  color = GLOBAL_COLOR.GRAY_500,
  className,
  children,
  onIconButtonClick,
  gap,
}: IconButtonProps) => {
  return (
    <StyledIconButton className={className} onClick={onIconButtonClick} gap={gap}>
      <FlatIcon iconType={iconType} size={size} color={color} />
      {children}
    </StyledIconButton>
  );
};

export default IconButton;

const StyledIconButton = styled.button<StyledIconButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap || 0}px;
`;
