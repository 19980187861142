import React, { useCallback } from 'react';
import BottomSheetDefaultHeader from '@/components/common/BottomSheetDefaultHeader';
import RadioSelectList from '@/components/common/radios/RadioSelectList';
import BottomSheetModal from '@/components/modal/BottomSheetModal';
import { Sort } from '@/constants/filter';
import { ModalProps } from '@/hooks/useModal';
import { GLOBAL_COLOR } from '@/styles/colors';

interface ListSortModalProps {
  sort: Sort;
  handleSortSelect: (i: Sort) => void;
  sortModalProps: ModalProps;
  sortFilter: Sort[];
  title?: string;
}

const ListSortModal = ({
  sort,
  handleSortSelect,
  sortModalProps,
  sortFilter,
  title = '리스트 정렬',
}: ListSortModalProps) => {
  const handleCloseSortModal = useCallback(() => {
    sortModalProps.hideModal();
  }, [sortModalProps]);

  return (
    <BottomSheetModal
      contentPadding={{ top: '0', bottom: '0', right: '0', left: '0' }}
      header={
        <BottomSheetDefaultHeader
          title={title}
          rightButton={{ iconType: 'icClose', size: 24, color: GLOBAL_COLOR.GRAY_300 }}
          onClickRightButton={handleCloseSortModal}
        />
      }
      content={<RadioSelectList itemList={sortFilter} selectedValue={sort} handleSelect={handleSortSelect} />}
      modalProps={sortModalProps}
    />
  );
};

export default React.memo(ListSortModal);
