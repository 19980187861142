import styled from '@emotion/styled';
import React from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface SelectItemProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const SelectItem = ({ label, isSelected, onClick }: SelectItemProps) => {
  return (
    <Wrapper isSelected={isSelected} onClick={onClick}>
      <span>{label}</span>
      {isSelected && <FlatIcon iconType={'icCheck'} size={24} color={GLOBAL_COLOR.BLUE_500} />}
    </Wrapper>
  );
};

export default SelectItem;

interface WrapperProps {
  isSelected: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: ${(props) => (props.isSelected ? 600 : 400)};
    color: ${(props) => (props.isSelected ? GLOBAL_COLOR.BLUE_500 : GLOBAL_COLOR.GRAY_900)};
    padding: ${toRem(10)} 0;
    font-size: ${toRem(15)};
    line-height: ${toRem(21)};
  }
`;
